import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inactive-link',
  templateUrl: './inactive-link.component.html',
  styleUrls: ['./inactive-link.component.scss']
})
export class InactiveLinkComponent implements OnInit {

  link!: string;

  constructor(private route: ActivatedRoute,) { }

  ngOnInit(): void {

    const linkId = this.route.snapshot.params['linkId'];

    this.link = `apply.godzillo.cc/${linkId}`;
  }

}
