import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ErrorComponent } from './components/error/error.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';

@NgModule({
  declarations: [
    NotFoundComponent,
    ErrorComponent,
    PhoneMaskDirective,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
  ],
  exports:[
    MaterialModule,
    FlexLayoutModule,
    NgxSkeletonLoaderModule,
    PhoneMaskDirective,
  ],
})
export class SharedModule { }
