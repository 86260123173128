import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { ErrorComponent, ErrorData } from './components/error/error.component';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private dialog: MatDialog) { }

  showErrorMessage(error: ErrorData) {
    this.dialog.open(ErrorComponent, { data: error, minWidth: '300px', width: '400px', disableClose: true});
  }

}
